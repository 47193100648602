import React from "react";
import { useEffect } from "react";
import classes from "../css/buttons.module.css";

const PrimaryButton = ({ onClick, buttonText, disabled }) => {
  return (
    <button
      className={
        `${classes["primary-btn"]}` +
        " " +
        (disabled ? `${classes["disabled-btn"]}` : "")
      }
      onClick={onClick}
    >
      {buttonText}
    </button>
  );
};

export default PrimaryButton;
