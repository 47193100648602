import { useContext, useEffect, useState } from "react";
import { Alert, Collapse } from "react-bootstrap";

import classes from "../css/flash.module.css";
import { FlashContext } from "../contexts/FlashProvider";

export default function FlashMessage({ dismissTime = 2000 }) {
  const { flashMessage, visible, hideFlash } = useContext(FlashContext);
  const [internalVisible, setInternalVisible] = useState(visible);

  useEffect(() => {
    setInternalVisible(visible);
    if (visible) {
      const timer = setTimeout(() => {
        hideFlash();
      }, dismissTime);

      return () => clearTimeout(timer);
    }
  }, [visible, hideFlash, dismissTime]);

  return (
    <div
      className={
        `${classes["flash-msg"]}` +
        " " +
        (internalVisible ? `${classes["visible"]}` : "")
      }
    >
      {internalVisible && (
        <Alert
          variant={flashMessage.type || "info"}
          dismissible
          onClose={() => {
            hideFlash();
          }}
        >
          <p className={classes["flash-msg-content"]}>{flashMessage.message}</p>
        </Alert>
      )}
    </div>
  );
}
