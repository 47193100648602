import classes from "../css/home.module.css";

export default function Home() {
  return (
    <div className={classes["full-screen-container"]}>
      <div className={classes["center-section-container"]}>
        <h3>Workout Tracker</h3>
        <h3>Get a grip on your routine</h3>
        <div className={classes["already-member"]}>
          <span>Already a member?</span>
          <a href="/login">Login</a>
        </div>
        <div className={classes["signup-container"]}>
          <a href="/register">
            <button>Signup With Email Today</button>
          </a>
        </div>
      </div>
    </div>
  );
}
