import { useState, useEffect } from "react";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import { Tooltip, OverlayTrigger } from "react-bootstrap";

import classes from "../css/header.module.css";
import moonLogo from "../icons/moon-black.svg";
import sunLogo from "../icons/sun-black.svg";
import MenuLogo from "./svg-components/MenuLogo";
import { useUser } from "../contexts/UserProvider";
import "../css/theme.css";

export default function Header() {
  const { user, logout, theme, toggleTheme } = useUser();
  const navigate = useNavigate();
  const location = useLocation();
  const [activeHeaderOption, setActiveHeaderOption] = useState();
  const [userInitial, setUserInitial] = useState();

  // const handleLinkClick = (e) => {
  //   setActiveHeaderOption(e.target.getAttribute("data-value"));
  // };

  useEffect(() => {
    setUserInitial(
      user && user.first_name ? user.first_name[0].toUpperCase() : ""
    );
  }, [user]);

  useEffect(() => {
    const path = location.pathname;
    if (user) {
      if (path.startsWith("/feed")) {
        setActiveHeaderOption("feed");
      } else if (
        path === "/plans/new" ||
        path === `/user/${Number(user.id)}/plans`
      ) {
        setActiveHeaderOption("plan");
      } else if (
        path === "/posts/new" ||
        path === `/user/${Number(user.id)}/posts`
      ) {
        setActiveHeaderOption("post");
      } else {
        setActiveHeaderOption(""); // Clear active option if path doesn't match
      }
    }
  }, [user, location]);

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {theme === "dark" ? "light mode" : "dark mode"}
    </Tooltip>
  );

  return (
    <div className={classes["header"]} data-bs-theme={theme}>
      {user ? (
        <>
          <div className={classes["middle-section"]}>
            <div className={classes["hamburger-menu"]}>
              <MenuLogo />
            </div>

            <div className={classes["middle-section-links"]}>
              <NavLink
                className={
                  `${classes["nav-link"]}` +
                  " " +
                  (activeHeaderOption === "feed"
                    ? `${classes["active-header-option"]}`
                    : "")
                }
                key="feed"
                to="/feed"
                data-value="feed"
                // onClick={handleLinkClick}
              >
                Feed
              </NavLink>
              <div className={classes["dropdown"]}>
                <NavLink
                  className={
                    `${classes["nav-link"]}` +
                    " " +
                    (activeHeaderOption === "plan"
                      ? `${classes["active-header-option"]}`
                      : "")
                  }
                  key="plans"
                  to={`/user/${user.id}/plans`}
                  data-value={`/user/${user.id}/plans`}
                  // onClick={handleLinkClick}
                >
                  Plan
                </NavLink>
                <div className={classes["dropdown-content-2"]}>
                  <button
                    onClick={() => {
                      navigate(`/user/${user.id}/plans`);
                    }}
                  >
                    My Plans
                  </button>
                  <button
                    onClick={() => {
                      navigate("/plans/new");
                    }}
                  >
                    Add Plan
                  </button>
                </div>
              </div>

              <div className={classes["dropdown"]}>
                <NavLink
                  className={
                    `${classes["nav-link"]}` +
                    " " +
                    (activeHeaderOption === "post"
                      ? `${classes["active-header-option"]}`
                      : "")
                  }
                  key="posts"
                  to={`/user/${user.id}/posts`}
                  data-value={`/user/${user.id}/posts`}
                  // onClick={handleLinkClick}
                >
                  Post
                </NavLink>
                <div className={classes["dropdown-content-2"]}>
                  <button
                    onClick={() => {
                      navigate(`/user/${user.id}/posts`);
                    }}
                  >
                    My Posts
                  </button>
                  <button
                    onClick={() => {
                      navigate("/posts/new");
                    }}
                  >
                    Add Post
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className={classes["right-section"]}>
            <div>
              <OverlayTrigger
                placement="bottom"
                delay={{ show: 150, hide: 400 }}
                overlay={renderTooltip}
              >
                <button
                  onClick={() => {
                    toggleTheme();
                  }}
                  className={classes["logo-btn"]}
                >
                  <img
                    src={theme === "dark" ? sunLogo : moonLogo}
                    className={classes["logo"]}
                  />
                </button>
              </OverlayTrigger>
            </div>
            <div className={classes["dropdown"]}>
              <button className={classes["profile-button"]}>
                <span className={classes["post-profile-picture"]}>
                  {userInitial}
                </span>
              </button>
              <div className={classes["dropdown-content"]}>
                <button
                  onClick={() => {
                    navigate(`/user/${user.id}/posts`);
                  }}
                >
                  Profile
                </button>
                <button
                  onClick={() => {
                    navigate("/settings");
                  }}
                >
                  Settings
                </button>
                <button onClick={logout}>Logout</button>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className={classes["left-section"]}>
            <NavLink className={classes["home-link"]} key="home" to="/">
              Home
            </NavLink>
          </div>
          <div className={classes["hidden"]}></div>
          <div className={classes["right-section"]}>
            <button className={classes["signup-button"]}>
              <NavLink
                className={classes["signup-nav-link"]}
                key="sign-up"
                to="/register"
              >
                Sign Up
              </NavLink>
            </button>
          </div>
        </>
      )}
    </div>
  );
}
