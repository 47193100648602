import { useState, useEffect } from "react";
import classes from "../css/trends.module.css";
import { Line } from "react-chartjs-2";
import { useApi } from "../contexts/ApiProvider";
import { useUser } from "../contexts/UserProvider";
import Body from "./Body";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Colors,
} from "chart.js";
import Dropdown from "./Dropdown";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export default function Trends() {
  const { theme } = useUser();
  const [trendData, setTrendData] = useState({ dates: [], weights: [] });
  const [exerciseList, setExerciseList] = useState([]);
  const [selectedExercise, setSelectedExercise] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const [textColor, setTextColor] = useState("black");
  const api = useApi();

  useEffect(() => {
    {
      theme === "light" ? setTextColor("black") : setTextColor("white");
    }
  }, [theme]);

  const formatter = new Intl.DateTimeFormat("en-US", {
    year: "2-digit",
    month: "numeric",
    day: "numeric",
  });

  const footer = (tooltipItems) => {
    // Get the data index from the first tooltip item
    const dataIndex = tooltipItems[0].dataIndex;
    // Get the dataset index from the first tooltip item
    const datasetIndex = tooltipItems[0].datasetIndex;
    // Retrieve the reps value from the data
    const reps =
      tooltipItems[0].chart.data.datasets[datasetIndex].reps[dataIndex];
    return `Reps: ${reps}`;
  };

  const options = {
    layout: {
      padding: {
        left: 20,
      },
    },
    plugins: {
      legend: {
        labels: {
          color: textColor,
          usePointStyle: true,
          pointStyle: "line",
        },
      },
      tooltip: {
        callbacks: {
          footer: footer,
        },
      },
    },
    scales: {
      x: {
        ticks: {
          color: textColor,
          minRotation: 45,
          maxRotation: 45,
        },
        grid: {
          color: "hsl(0, 0%, 50%)",
        },
      },
      y: {
        ticks: {
          color: textColor,
          stepSize: 5,
          // autoSkip: false,
        },
        grid: {
          color: "hsl(0, 0%, 50%)",
        },
        beginAtZero: true,
        suggestedMax: 2 * Math.max(...trendData.weights),
      },
    },
    maintainAspectRatio: false,
  };

  const data = {
    labels: trendData.dates,
    datasets: [
      {
        label: selectedExercise.name,
        data: trendData.weights,
        reps: trendData.reps,
        borderColor: "rgb(75, 192, 192)",
      },
    ],
  };

  useEffect(() => {
    (async () => {
      const response = await api.get("/completed-exercises");
      if (response.ok) {
        response.body.sort((a, b) => a.name.localeCompare(b.name));
        setExerciseList(response.body);
        console.log(response.body);
      } else {
        console.log("error getting exercise list");
      }
    })();
  }, [api]);

  useEffect(() => {
    if (selectedExercise) {
      getExerciseHistory();
    }
  }, [selectedExercise]);

  const getExerciseHistory = async () => {
    const response = await api.get("/exercise-history/" + selectedExercise.id);
    if (response.ok) {
      console.log(response.body);
      const maxWeightsData = filterByMaxWeight(response.body);
      const dates = [];
      const weights = [];
      const reps = [];

      maxWeightsData.forEach((entry) => {
        const date_formatted = formatter.format(new Date(entry.date));
        dates.push(date_formatted);
        weights.push(entry.weight);
        reps.push(entry.reps);
      });
      setTrendData({
        dates: dates.slice(-10),
        weights: weights.slice(-10),
        reps: reps.slice(-10),
      });
    } else {
      console.log("error getting exercise history");
    }
  };

  function filterByMaxWeight(objects) {
    const uniqueObjects = {};

    objects.forEach((obj) => {
      const date = obj.date;
      if (!uniqueObjects[date] || obj.weight > uniqueObjects[date].weight) {
        uniqueObjects[date] = obj;
      }
    });

    return Object.values(uniqueObjects);
  }

  function handleDropdownItemSelection(e) {
    setSelectedExercise({
      id: Number(e.target.id),
      name: e.target.textContent,
    });
    setShowDropdown(false);
  }

  return (
    <Body>
      <div className={classes["trends-container"]}>
        <div className={classes["trends-header"]}>
          <h2>Trends</h2>
        </div>
        <Dropdown
          dropdownOptions={exerciseList}
          selectedDropdownOption={selectedExercise}
          handleDropdownItemSelection={handleDropdownItemSelection}
        />
        {selectedExercise ? (
          <div className={classes["canvas-container"]}>
            <Line options={options} data={data} />
          </div>
        ) : null}
      </div>
    </Body>
  );
}
