export default function format_date(dateTimeString) {
  const dateTime = new Date(dateTimeString);

  const formattedDate = dateTime.toLocaleDateString(undefined, {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  });

  return formattedDate;
}
