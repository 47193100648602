import { useState, useEffect, useCallback, useRef } from "react";
import { Spinner, ListGroup } from "react-bootstrap";

import classes from "../css/post.module.css";
import { useApi } from "../contexts/ApiProvider";
import Post from "./Post";
import LoadingIcon from "../components/LoadingIcon";

export default function Posts({ content }) {
  const api = useApi();
  const [posts, setPosts] = useState(undefined);
  const [pageNumber, setPageNumber] = useState(1);
  const [hasMorePosts, setHasMorePosts] = useState(true);
  const [loading, setLoading] = useState(false);
  const [url, setUrl] = useState(null);
  const [message, setMessage] = useState("");

  useEffect(() => {
    setPageNumber(1);
    setHasMorePosts(true);
    setPosts(undefined);
    switch (content) {
      case "following":
        setUrl(`/posts/following`);
        setMessage("Start following users to see posts here");
        break;
      case "explore":
        setUrl("/posts");
        setMessage("No posts are available");
        break;
      default:
        setUrl(`/user/${content.id}/posts`);
        setMessage("Post a workout to begin");
    }
  }, [content]);

  useEffect(() => {
    (async () => {
      if (url) getPosts();
    })();
  }, [api, url, pageNumber]);

  const getPosts = async () => {
    setLoading(true);
    const response = await api.get(`${url}` + `?page=${pageNumber}`);
    if (response.ok) {
      const newPosts =
        posts && posts.length > 0
          ? [...posts, ...response.body.posts]
          : response.body.posts;

      setPosts(newPosts);
      console.log(newPosts);
      setLoading(false);
      if (!response.body.more) setHasMorePosts(false);
    } else {
      setPosts(null);
    }
  };
  const resetPosts = async () => {
    setPageNumber(1);
    setHasMorePosts(true);
    setPosts(undefined);
    await getPosts();
  };

  const observer = useRef();
  const lastPostElementRef = useCallback((node) => {
    if (loading) return;
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && hasMorePosts) {
        setPageNumber(pageNumber + 1);
      }
    });
    if (node) observer.current.observe(node);
  });

  return (
    <>
      {posts === undefined ? (
        <LoadingIcon />
      ) : (
        <>
          {posts === null ? (
            <p>Could not retrieve posts</p>
          ) : (
            <>
              {posts.length === 0 ? (
                <p className={classes["not-found-msg"]}>{message}</p>
              ) : (
                <>
                  <ListGroup className={classes["custom-list-group"]}>
                    {posts.map((post, post_idx) => {
                      if (post_idx + 1 === posts.length) {
                        return (
                          <Post
                            key={post_idx}
                            ref={lastPostElementRef}
                            post={post}
                            resetPosts={getPosts}
                          />
                        );
                      } else {
                        return (
                          <Post
                            myAccount={content.myAccount}
                            key={post_idx}
                            post={post}
                            resetPosts={resetPosts}
                          />
                        );
                      }
                    })}
                  </ListGroup>
                  <div className={classes["loading-container"]}>
                    {loading ? <Spinner /> : null}
                  </div>
                </>
              )}
            </>
          )}
        </>
      )}
    </>
  );
}
