import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import classes from "../css/register.module.css";
import { useApi } from "../contexts/ApiProvider";
import { useFlash } from "../contexts/FlashProvider";
import Body from "../components/Body";

export default function ResetPage() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get("token");

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [detailedPasswordError, setDetailedPasswordError] = useState("");

  const [formErrors, setFormErrors] = useState({});
  const [formSuccess, setFormSuccess] = useState(false);

  const navigate = useNavigate();
  const flash = useFlash();
  const api = useApi();

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(token);
    if (validateFormEntries()) {
      const response = await api.put("/reset", {
        token: token,
        password: password,
      });
      if (response.ok) {
        flash("Password reset successfully", "success");
        setFormSuccess(true);
        setTimeout(() => {
          navigate("/");
        }, 500);
      } else {
        flash("Error resetting password. Try again.", "danger");
      }
    }
  };

  function validateFormEntries() {
    setFormErrors({});
    const errors = {};

    let confirmPasswordRequiredError = true;
    let isValid = true;

    if (!password) {
      errors.password = "Required";
    } else if (!validatePassword(password)) {
      errors.password = "Password does not meet requirements [see more]";
      setDetailedPasswordError(
        "Password must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, one digit, and one special character (@$!%*?&)"
      );
    }

    if (!confirmPassword) {
      errors.confirm_password = "Required";
      confirmPasswordRequiredError = true;
    }

    if (
      !formErrors.password &&
      confirmPassword &&
      confirmPasswordRequiredError &&
      password !== confirmPassword
    ) {
      errors.confirm_password = "Password does not match";
    }

    setFormErrors(errors);
    if (Object.keys(errors).length !== 0) {
      isValid = false;
    }
    return isValid;
  }

  const validatePassword = (password) => {
    const passwordPattern =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return passwordPattern.test(password);
  };

  return (
    <Body>
      <div className={classes["signup-container"]}>
        <h3 className={classes["signup-title"]}>Reset Password</h3>
        <form
          className={
            `${classes["form"]}` +
            " " +
            (formSuccess ? `${classes["success"]}` : "")
          }
          onSubmit={handleSubmit}
          noValidate
        >
          <div
            className={
              `${classes["input-group"]}` +
              " " +
              (formErrors.password ? `${classes["error"]}` : "")
            }
          >
            <label htmlFor="password">New Password</label>
            <input
              type="password"
              name="password"
              id="password"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
            <span className={classes["msg"]}>{formErrors.password}</span>
            <span className={classes["detailed-msg"]}>
              {detailedPasswordError}
            </span>
          </div>
          <div
            className={
              `${classes["input-group"]}` +
              " " +
              (formErrors.confirm_password ? `${classes["error"]}` : "")
            }
          >
            <label htmlFor="confirmPassword">Confirm New Password</label>
            <input
              type="password"
              name="confirmPassword"
              id="confirmPassword"
              value={confirmPassword}
              onChange={(e) => {
                setConfirmPassword(e.target.value);
              }}
            />
            <span className={classes["msg"]}>
              {formErrors.confirm_password}
            </span>
          </div>
          <button className={classes["signup-button"]}>Submit</button>
        </form>
      </div>
    </Body>
  );
}
