import { useState } from "react";

import classes from "../css/register.module.css";
import { useApi } from "../contexts/ApiProvider";
import { useFlash } from "../contexts/FlashProvider";
import Body from "../components/Body";

export default function ResetRequestPage() {
  const [email, setEmail] = useState();
  const [formErrors, setFormErrors] = useState({});
  const [formSuccess, setFormSuccess] = useState();

  const flash = useFlash();
  const api = useApi();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateFormEntries()) {
      const response = await api.post("/reset", {
        email: email,
      });
      if (response.ok) {
        flash(
          "You will receive an email with instructions " +
            "to reset your password.",
          "info"
        );
        setFormSuccess(true);
      } else {
        flash("Error requesting password reset. Try again.", "danger");
      }
    }
  };

  function validateFormEntries() {
    setFormErrors({});
    const errors = {};

    let isValid = true;

    if (!email) {
      errors.email = "Required";
    } else if (!validateEmail(email)) {
      errors.email = "Invalid Email";
    }

    setFormErrors(errors);
    if (Object.keys(errors).length !== 0) {
      isValid = false;
    }

    return isValid;
  }

  const validateEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  return (
    <Body>
      <div className={classes["signup-container"]}>
        <h3 className={classes["signup-title"]}>Request Password Reset</h3>
        <form
          className={
            `${classes["form"]}` +
            " " +
            (formSuccess ? `${classes["success"]}` : "")
          }
          onSubmit={handleSubmit}
          noValidate
        >
          <div
            className={
              `${classes["input-group"]}` +
              " " +
              (formErrors.email ? `${classes["error"]}` : "")
            }
          >
            <label htmlFor="email">Email</label>
            <input
              type="email"
              name="email"
              id="email"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
            <span className={classes["msg"]}>{formErrors.email}</span>
          </div>
          <button type="submit" className={classes["signup-button"]}>
            Submit
          </button>
        </form>
      </div>
    </Body>
  );
}
