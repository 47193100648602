import React from "react";

import classes from "../css/buttons.module.css";

const DeleteButton = ({ onClick }) => {
  return (
    <button className={classes["dots-menu-button"]} onClick={onClick}>
      <div className={classes["dots-menu"]}>
        <div className={classes["dot"]}></div>
        <div className={classes["dot"]}></div>
        <div className={classes["dot"]}></div>
      </div>
    </button>
  );
};

export default DeleteButton;
