import React, { useState } from "react";
import classes from "../css/dropdown.module.css";

export default function Dropdown({
  dropdownOptions,
  activeDropdownOption,
  handleDropdownItemSelection,
}) {
  const [showDropdown, setShowDropdown] = useState(false);

  function handleDropdownSelection(e) {
    setShowDropdown(false);
    handleDropdownItemSelection(e);
  }

  return (
    <div className={classes["dropdown"]}>
      <div
        className={classes["select"]}
        onClick={() => {
          setShowDropdown(!showDropdown);
        }}
      >
        <span className={classes["selected"]}>
          {activeDropdownOption ? activeDropdownOption : "Select"}
        </span>
        <div
          className={
            `${classes["caret"]}` +
            " " +
            (showDropdown ? `${classes["3caret-rotate"]}` : "")
          }
        ></div>
      </div>
      <ul
        className={
          `${classes["menu"]}` +
          " " +
          (showDropdown ? `${classes["menu-open"]}` : "")
        }
      >
        {dropdownOptions.map((option, index) => {
          return (
            <li
              key={index}
              className={
                activeDropdownOption === option ? `${classes["active"]}` : ""
              }
              data-name={option.name ? option.name : option.title}
              id={option.id}
              onClick={handleDropdownSelection}
            >
              {option.name ? option.name : option.title}
            </li>
          );
        })}
      </ul>
    </div>
  );
}
