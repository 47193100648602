import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import { NavLink } from "react-router-dom";

import classes from "../css/sidebar.module.css";

export default function SettingsSidebar({ userid, type }) {
  return (
    <Navbar sticky="top" className={classes["sidebar"]}>
      <Nav.Item
        className={type === "settings" && `${classes["active-sidebar"]}`}
      >
        <Nav.Link as={NavLink} to={`/settings`}>
          Profile Information
        </Nav.Link>
      </Nav.Item>
      <Nav.Item
        className={type === "preferences" && `${classes["active-sidebar"]}`}
      >
        <Nav.Link as={NavLink} to={`/settings/preferences`}>
          Preferences
        </Nav.Link>
      </Nav.Item>
      <Nav.Item
        className={type === "password" && `${classes["active-sidebar"]}`}
      >
        <Nav.Link as={NavLink} to={`/settings/password`}>
          Change Password
        </Nav.Link>
      </Nav.Item>
    </Navbar>
  );
}
