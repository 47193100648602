import { useState, createContext } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import ApiProvider from "./contexts/ApiProvider";
import FlashProvider from "./contexts/FlashProvider";
import UserProvider from "./contexts/UserProvider";

import Error from "./components/Error";
import Home from "./components/Home";
import Header from "./components/Header";
import PublicRoute from "./components/PublicRoute";
import PrivateRoute from "./components/PrivateRoute";
import Trends from "./components/Trends";
import Plans from "./components/Plans";

import AddPlanPage from "./pages/AddPlanPage";
import AddPostPage from "./pages/AddPostPage";
import ChangePasswordPage from "./pages/ChangePasswordPage";
import EditPlanPage from "./pages/EditPlanPage";
import FeedPage from "./pages/FeedPage";
import LoginPage from "./pages/LoginPage";
import PlanPage from "./pages/PlanPage";
import RegisterPage from "./pages/RegisterPage";
import ResetPage from "./pages/ResetPage";
import ResetRequestPage from "./pages/ResetRequestPage";
import UserPage from "./pages/UserPage";
import SettingsPage from "./pages/SettingsPage";

function App() {
  const [key, setKey] = useState(0);

  // const handleLinkClick = () => {
  //   setKey((prevKey) => prevKey + 1); // Increment the key to force a rerender
  // };

  return (
    <div>
      <BrowserRouter>
        <FlashProvider>
          <ApiProvider>
            <UserProvider>
              {/* <Header handleLinkClick={handleLinkClick} /> */}
              <Header />
              <Routes>
                <Route
                  path="/"
                  element={
                    <PublicRoute>
                      <Home />
                    </PublicRoute>
                  }
                />
                <Route
                  path="/login"
                  element={
                    <PublicRoute>
                      <LoginPage />
                    </PublicRoute>
                  }
                />
                <Route
                  path="/register"
                  element={
                    <PublicRoute>
                      <RegisterPage />
                    </PublicRoute>
                  }
                />
                <Route
                  path="/reset-request"
                  element={
                    <PublicRoute>
                      <ResetRequestPage />
                    </PublicRoute>
                  }
                />
                <Route
                  path="/reset"
                  element={
                    <PublicRoute>
                      <ResetPage />
                    </PublicRoute>
                  }
                />
                <Route
                  path="*"
                  element={
                    <PrivateRoute>
                      <Routes>
                        <Route path="/trends" element={<Trends key={key} />} />
                        <Route path="/error" element={<Error />} />
                        <Route path="/feed" element={<FeedPage />} />
                        <Route
                          path="/user/:userid/plan/:planid"
                          element={<PlanPage />}
                        />
                        <Route
                          path="/plan/:planid/edit"
                          element={<EditPlanPage />}
                        />
                        <Route path="/plans/new" element={<AddPlanPage />} />
                        <Route path="/posts/new" element={<AddPostPage />} />
                        <Route
                          path="/settings"
                          element={<SettingsPage type="settings" />}
                        />
                        <Route
                          path="/user/:userid/posts"
                          element={<UserPage type="posts" />}
                        />
                        <Route
                          path="/user/:userid/plans"
                          element={<UserPage type="plans" />}
                        />
                        <Route
                          path="/settings/password"
                          element={<SettingsPage type="password" />}
                        />
                        <Route
                          path="/settings/preferences"
                          element={<SettingsPage type="preferences" />}
                        />
                      </Routes>
                    </PrivateRoute>
                  }
                ></Route>
              </Routes>
            </UserProvider>
          </ApiProvider>
        </FlashProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
