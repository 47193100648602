import React from "react";

import classes from "../css/modal.module.css";
import SecondaryButton from "./SecondaryButton";
import DeleteButton from "./DeleteButton";

const Modal = ({ open, onClose, onConfirm, msg }) => {
  if (!open) return null;

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <>
      <div className={classes["overlay"]} onClick={handleOverlayClick} />
      <div className={classes["modal"]}>
        <div className={classes["msg"]}>
          <span>{msg}</span>
        </div>
        <div className={classes["btn-container"]}>
          <SecondaryButton onClick={onClose} buttonText="Cancel" />
          <DeleteButton onClick={onConfirm} />
        </div>
      </div>
    </>
  );
};

export default Modal;
