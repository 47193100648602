import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import classes from "../css/change-password.module.css";
import { useApi } from "../contexts/ApiProvider";
import { useFlash } from "../contexts/FlashProvider";
import Body from "../components/Body";

export default function ChangePasswordPage() {
  const flash = useFlash();
  const [oldPassword, setCurrentPassword] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [detailedPasswordError, setDetailedPasswordError] = useState("");
  const [formErrors, setFormErrors] = useState({});
  const [formSuccess, setFormSuccess] = useState(false);

  const navigate = useNavigate();
  const api = useApi();

  const ChangePassword = async (e) => {
    console.log("changing password...");
    e.preventDefault();
    if (validateFormEntries()) {
      console.log("entries validated");
      const response = await api.put("/me", {
        old_password: oldPassword,
        new_password: password,
      });
      if (response.ok) {
        flash("Password changed successfully", "success");
        setFormSuccess(true);
        setTimeout(() => {
          navigate("/feed");
        }, 500);
      } else {
        flash(response.body.msg, "danger");
      }
    }
  };

  function validateFormEntries() {
    setFormErrors({});
    const errors = {};

    let confirmPasswordRequiredError = true;
    let isValid = true;

    if (!oldPassword) {
      errors.oldPassword = "Required";
    }

    if (!password) {
      errors.password = "Required";
    } else if (!validatePassword(password)) {
      errors.password = "Password does not meet requirements [see more]";
      setDetailedPasswordError(
        "Password must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, one digit, and one special character (@$!%*?&)"
      );
    }

    if (!confirmPassword) {
      errors.confirm_password = "Required";
      confirmPasswordRequiredError = true;
    }

    if (
      !formErrors.password &&
      confirmPassword &&
      confirmPasswordRequiredError &&
      password !== confirmPassword
    ) {
      errors.confirm_password = "Password does not match";
    }

    setFormErrors(errors);
    if (Object.keys(errors).length !== 0) {
      isValid = false;
    }
    return isValid;
  }

  const validatePassword = (password) => {
    const passwordPattern =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return passwordPattern.test(password);
  };
  return (
    <form
      className={
        `${classes["form"]}` +
        " " +
        (formSuccess ? `${classes["success"]}` : "")
      }
      onSubmit={ChangePassword}
      noValidate
    >
      <div
        className={
          `${classes["input-group"]}` +
          " " +
          (formErrors.oldPassword ? `${classes["error"]}` : "")
        }
      >
        <label htmlFor="oldPassword">Old Password</label>
        <input
          type="password"
          name="oldPassword"
          id="oldPassword"
          value={oldPassword}
          onChange={(e) => {
            setCurrentPassword(e.target.value);
          }}
        />
        <span className={classes["msg"]}>{formErrors.oldPassword}</span>
        <span className={classes["detailed-msg"]}>{detailedPasswordError}</span>
      </div>
      <div
        className={
          `${classes["input-group"]}` +
          " " +
          (formErrors.password ? `${classes["error"]}` : "")
        }
      >
        <label htmlFor="password">New Password</label>
        <input
          type="password"
          name="password"
          id="password"
          value={password}
          onChange={(e) => {
            setPassword(e.target.value);
          }}
        />
        <span className={classes["msg"]}>{formErrors.password}</span>
        <span className={classes["detailed-msg"]}>{detailedPasswordError}</span>
      </div>
      <div
        className={
          `${classes["input-group"]}` +
          " " +
          (formErrors.confirm_password ? `${classes["error"]}` : "")
        }
      >
        <label htmlFor="confirmPassword">Confirm New Password</label>
        <input
          type="password"
          name="confirmPassword"
          id="confirmPassword"
          value={confirmPassword}
          onChange={(e) => {
            setConfirmPassword(e.target.value);
          }}
        />
        <span className={classes["msg"]}>{formErrors.confirm_password}</span>
      </div>
      <button className={classes["signup-button"]}>Submit</button>
    </form>
  );
}
