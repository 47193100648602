import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import { NavLink } from "react-router-dom";

import classes from "../css/sidebar.module.css";

export default function Sidebar({ userid, type }) {
  return (
    <Navbar styles={{ padding: "0" }} className={classes["sidebar"]}>
      <Nav.Item className={type === "posts" && `${classes["active-sidebar"]}`}>
        <Nav.Link as={NavLink} to={`/user/${userid}/posts`}>
          Posts
        </Nav.Link>
      </Nav.Item>
      <Nav.Item className={type === "plans" && `${classes["active-sidebar"]}`}>
        <Nav.Link as={NavLink} to={`/user/${userid}/plans`}>
          Plans
        </Nav.Link>
      </Nav.Item>
    </Navbar>
  );
}
