import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import classes from "../css/workout-plans.module.css";
import { useApi } from "../contexts/ApiProvider";
import { useFlash } from "../contexts/FlashProvider";
import { useNavigate } from "react-router-dom";
import Body from "../components/Body";
import ExerciseGrid from "../components/ExerciseGrid";
import PlanDetails from "../components/PlanDetails";
import PrimaryButton from "../components/PrimaryButton";
import SecondaryButton from "../components/SecondaryButton";

export default function PlanPage() {
  const api = useApi();
  const flash = useFlash();
  const navigate = useNavigate();
  const { planid } = useParams();
  const [exercisePlans, setExercisePlans] = useState();
  const [planTitle, setPlanTitle] = useState("");
  const [planTitleError, setPlanTitleError] = useState();
  const [workoutPlan, setWorkoutPlan] = useState();
  const [submitIsValid, setSubmitIsValid] = useState();
  const [readOnlyMode, setReadOnlyMode] = useState(true);
  const [exerciseOptions, setExerciseOptions] = useState([]);

  const updateExercisePlanParams = (plans) => {
    const updatedExercisePlans = plans.map((exercisePlan) => {
      console.log(exercisePlan);
      const { workout_plan_id, exercise, ...plan } = exercisePlan;
      plan.exercise_id = exercise.id;
      plan.exercise_name = exercise.name;
      return plan;
    });
    setExercisePlans(updatedExercisePlans);
  };

  useEffect(() => {
    (async () => {
      const response = await api.get(`/workoutplan/${planid}`);
      if (response.ok) {
        const exercisePlans = response.body.exercise_plans;
        setExercisePlans(exercisePlans);
        setWorkoutPlan(response.body);
        setPlanTitle(response.body.title);
        updateExercisePlanParams(exercisePlans);
      } else {
        setExercisePlans(null);
        setWorkoutPlan(null);
      }
    })();
  }, [api, planid]);

  const handleCancel = () => {
    navigate(`/plan/${planid}`);
  };

  const saveWorkoutPlan = async () => {
    if (planTitle === "") {
      setPlanTitleError(true);
      flash("Enter a workout plan title and try again!", "danger");
      return;
    }
    const updatedExercisePlans = exercisePlans.map((exercisePlan) => {
      const { exercise_name, ...plan } = exercisePlan;
      return plan;
    });

    const response = await api.put(`/workoutplan/${planid}`, {
      title: planTitle,
      exercise_plans: updatedExercisePlans,
    });
    if (response.ok) {
      setTimeout(() => {
        navigate(`/plan/${planid}`);
      }, 200);
      flash("Workout plan successfully updated", "success");
    } else {
      flash("Error updating workout plan", "danger");
    }
  };

  const handleTitleChange = (e) => {
    setPlanTitle(e.target.value);
  };

  return (
    <Body>
      <div className={classes["add-plan-container"]}>
        <div>
          <div>
            <div className={classes["edit-plan-header"]}>
              <div
                className={
                  `${classes["workout-plan-title"]}` +
                  " " +
                  (planTitleError ? `${classes["error"]}` : "")
                }
              >
                <input
                  type="text"
                  value={planTitle}
                  onChange={handleTitleChange}
                  placeholder="Plan Title"
                ></input>
                <span className={classes["msg"]}>Required</span>
              </div>
              <div className={classes["post-btn-container"]}>
                <SecondaryButton onClick={handleCancel} buttonText="Cancel" />
                <PrimaryButton onClick={saveWorkoutPlan} buttonText="Save" />
              </div>
            </div>
          </div>

          <PlanDetails
            exercisePlans={exercisePlans}
            setExercisePlans={setExercisePlans}
            isNewPlan={false}
            readOnlyMode={false}
            setSubmitIsValid={setSubmitIsValid}
          />
        </div>
        <ExerciseGrid
          exerciseOptions={exerciseOptions}
          setExerciseOptions={setExerciseOptions}
          exercisePlans={exercisePlans}
          setExercisePlans={setExercisePlans}
        />
      </div>
    </Body>
  );
}
