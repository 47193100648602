import classes from "../css/workout-plans.module.css";
import Body from "../components/Body";
import Posts from "../components/Posts";
import { useState } from "react";

export default function FeedPage() {
  const [postsFilter, setPostsFilter] = useState("following");
  return (
    <Body>
      <div className={classes["feed-container"]}>
        <div className={classes["feed-header"]}>
          <h2>Feed</h2>
        </div>
        <div className={classes["feed-btn-container"]}>
          <button
            className={
              postsFilter === "following" ? `${classes["active-feed"]}` : null
            }
            onClick={() => {
              setPostsFilter("following");
            }}
          >
            Following
          </button>
          <button
            className={
              postsFilter === "explore" ? `${classes["active-feed"]}` : null
            }
            onClick={() => {
              setPostsFilter("explore");
            }}
          >
            Explore
          </button>
        </div>
        <Posts content={postsFilter} />
      </div>
    </Body>
  );
}
