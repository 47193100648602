import classes from "../css/body.module.css";
import { useUser } from "../contexts/UserProvider";
import FlashMessage from "./FlashMessage";

export default function Body({ loggedOut, children }) {
  const { user, theme } = useUser();
  return (
    <div
      className={
        !loggedOut ? `${classes["body"]}` : `${classes["body-default"]}`
      }
      data-bs-theme={theme}
    >
      <div className={classes["content"]}>
        <FlashMessage />
        {children}
      </div>
    </div>
  );
}
