import { useState, useEffect, Fragment } from "react";
import {
  ListGroup,
  ListGroupItem,
  Spinner,
  OverlayTrigger,
  Tabs,
  Tab,
  Tooltip,
} from "react-bootstrap";

import classes from "../css/plan-details.module.css";
import React from "react";

export default function PlanDetails({
  exercisePlans,
  setExercisePlans,
  readOnlyMode,
  setSubmitIsValid,
}) {
  const removeExercise = (e) => {
    const exerciseId = Number(e.target.id);
    const updatedExercisePlans = exercisePlans.filter(
      (exercisePlan) => exercisePlan.exercise_id !== exerciseId
    );

    setExercisePlans(updatedExercisePlans);
  };

  const addSet = (e) => {
    const id = e.target.id;
    const [exercise_id, idx] = id.split("-");
    setExercisePlans((exercisePlans) =>
      exercisePlans.map((exercisePlan) => {
        if (exercisePlan.exercise_id === Number(exercise_id)) {
          if (exercisePlan.set_plans.length < 5) {
            const newSet = exercisePlan.set_plans[idx];
            exercisePlan.set_plans.splice(Number(idx) + 1, 0, newSet);
          }
          return exercisePlan;
        }
        return exercisePlan;
      })
    );
  };

  const removeSet = (e) => {
    const id = e.target.id;
    const [exerciseId, setIndex] = id.split("-").map(Number);

    setExercisePlans((exercisePlans) =>
      exercisePlans.map((exercisePlan) => {
        if (exercisePlan.exercise_id === exerciseId) {
          if (exercisePlan.set_plans.length > 1) {
            exercisePlan.set_plans.pop();
          }
          return exercisePlan;
        }
        return exercisePlan;
      })
    );
  };

  const handleRepChange = (e) => {
    const id = e.target.id;
    const [exerciseId, setIndex] = id.split("-").map(Number);

    let value = e.target.value;
    if (value === "") {
      setSubmitIsValid(false);
    } else {
      setSubmitIsValid(true);
    }

    if (value) {
      value = Number(value);
    }

    setExercisePlans((exercisePlans) =>
      exercisePlans.map((exercisePlan) => {
        if (exercisePlan.exercise_id === exerciseId) {
          return {
            ...exercisePlan,
            set_plans: exercisePlan.set_plans.map((set_plan, idx) =>
              idx === setIndex ? { ...set_plan, reps: value } : set_plan
            ),
          };
        }
        return exercisePlan;
      })
    );
  };

  const handleWeightChange = (e) => {
    const id = e.target.id;
    const [exerciseId, setIndex] = id.split("-").map(Number);

    let value = e.target.value;
    if (value === "") {
      setSubmitIsValid(false);
    } else {
      setSubmitIsValid(true);
    }

    if (value) {
      value = Number(value);
    }

    setExercisePlans((exercisePlans) =>
      exercisePlans.map((exercisePlan) => {
        if (exercisePlan.exercise_id === exerciseId) {
          return {
            ...exercisePlan,
            set_plans: exercisePlan.set_plans.map((set_plan, idx) =>
              idx === setIndex ? { ...set_plan, weight: value } : set_plan
            ),
          };
        }
        return exercisePlan;
      })
    );
  };

  const renderPlaceholders = (count) => {
    const placeholders = [];
    for (let i = 0; i < count; i++) {
      placeholders.push(
        <ListGroupItem
          style={{ height: "3rem" }}
          className={classes["placeholder-item"]}
          key={`placeholder-${i}`}
        >
          {/* Placeholder content */}
        </ListGroupItem>
      );
    }
    return placeholders;
  };

  const renderTooltip = (msg) => <Tooltip id="button-tooltip">{msg}</Tooltip>;

  return (
    <div className={classes["workout-plan-container"]}>
      {exercisePlans === undefined ? (
        <Spinner animation="border" />
      ) : (
        <div className={classes["plan-details-container"]}>
          <Tabs
            defaultActiveKey="summary"
            id="uncontrolled-tab-example"
            // className="mb-3"
            className={`${classes["plan-details-container"]}` + " " + "mb-3"}
          >
            <Tab eventKey="summary" title="Summary">
              <ListGroup style={{ width: "100%" }}>
                {exercisePlans.length === 0 ? (
                  <ListGroupItem
                    style={{ height: "3rem" }}
                    className={classes["list-item"]}
                  >
                    Start adding exercises!
                  </ListGroupItem>
                ) : (
                  <>
                    {exercisePlans.map((exercisePlan) => (
                      <ListGroupItem
                        style={{ height: "3rem" }}
                        className={classes["list-item"]}
                        key={exercisePlan.exercise_id}
                      >
                        {!readOnlyMode ? (
                          <button
                            id={exercisePlan.exercise_id}
                            className={classes["remove-exercise-button"]}
                            onClick={removeExercise}
                          >
                            x
                          </button>
                        ) : null}
                        <span className={classes["added-exercise-item"]}>
                          {exercisePlan.exercise_name}
                        </span>
                        {/* {exercisePlan.tags.map((tag) => (
                      <span className={classes["exercise-tag"]}>
                        {tag.name}
                      </span>
                    ))} */}
                      </ListGroupItem>
                    ))}
                    {renderPlaceholders(10 - exercisePlans.length)}
                  </>
                )}
              </ListGroup>
            </Tab>
            {exercisePlans.length !== 0 && (
              <Tab eventKey="detailed" title="Details">
                <table className={classes["plan-table"]}>
                  <thead>
                    <tr>
                      <th>Exercise</th>
                      <th>Reps</th>
                      <th>Weight</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {exercisePlans.map((exercisePlan, exercise_idx) =>
                      exercisePlan.set_plans.map((setPlan, idx) => (
                        <Fragment key={`${exercisePlan.exercise_id}-${idx}`}>
                          <tr
                            key={`${exercisePlan.exercise_id}-${idx}`}
                            className={
                              Number(exercise_idx) % 2 === 0
                                ? classes["shaded-row"]
                                : ""
                            }
                          >
                            {idx === 0 ? (
                              <td>{exercisePlan.exercise_name}</td>
                            ) : (
                              <td></td>
                            )}
                            <td>
                              {!readOnlyMode ? (
                                <input
                                  id={`${exercisePlan.exercise_id}-${idx}`}
                                  type="number"
                                  value={setPlan.reps}
                                  min="0"
                                  max="100"
                                  onChange={handleRepChange}
                                  className={
                                    setPlan.reps === ""
                                      ? `${classes["error"]}`
                                      : ""
                                  }
                                />
                              ) : (
                                <input
                                  id={`${exercisePlan.exercise_id}-${idx}`}
                                  type="number"
                                  value={setPlan.reps}
                                  disabled
                                  className={
                                    readOnlyMode
                                      ? `${classes["read-only"]}`
                                      : ""
                                  }
                                />
                              )}
                            </td>
                            <td>
                              {!readOnlyMode ? (
                                <input
                                  id={`${exercisePlan.exercise_id}-${idx}`}
                                  type="number"
                                  value={setPlan.weight}
                                  min="0"
                                  max="1000"
                                  step="5"
                                  onChange={handleWeightChange}
                                  className={
                                    setPlan.weight === ""
                                      ? `${classes["error"]}`
                                      : ""
                                  }
                                />
                              ) : (
                                <input
                                  id={`${exercisePlan.exercise_id}-${idx}`}
                                  type="number"
                                  value={setPlan.weight}
                                  disabled
                                  className={
                                    readOnlyMode
                                      ? `${classes["read-only"]}`
                                      : ""
                                  }
                                />
                              )}
                            </td>
                            <td>
                              {!readOnlyMode ? (
                                <div
                                  className={classes["set-button-container"]}
                                >
                                  <div
                                    className={classes["button-with-tooltip"]}
                                  >
                                    <OverlayTrigger
                                      placement="bottom"
                                      delay={{ show: 100, hide: 100 }}
                                      overlay={renderTooltip("remove set")}
                                    >
                                      <button
                                        id={`${exercisePlan.exercise_id}-${idx}`}
                                        className={classes["remove-set-button"]}
                                        onClick={removeSet}
                                      >
                                        -
                                      </button>
                                    </OverlayTrigger>
                                    <span className={classes["tooltip"]}>
                                      Delete Set
                                    </span>
                                  </div>
                                  <div
                                    className={classes["button-with-tooltip"]}
                                  >
                                    <OverlayTrigger
                                      placement="bottom"
                                      delay={{ show: 100, hide: 100 }}
                                      overlay={renderTooltip("duplicate set")}
                                    >
                                      <button
                                        id={`${exercisePlan.exercise_id}-${idx}`}
                                        className={classes["add-set-button"]}
                                        onClick={addSet}
                                      >
                                        +
                                      </button>
                                    </OverlayTrigger>
                                    <span className={classes["tooltip"]}>
                                      Add Set
                                    </span>
                                  </div>
                                </div>
                              ) : null}
                            </td>
                          </tr>
                        </Fragment>
                      ))
                    )}
                  </tbody>
                </table>
              </Tab>
            )}
          </Tabs>
        </div>
      )}
    </div>
  );
}
