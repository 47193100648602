import React from "react";

import classes from "../css/buttons.module.css";

const SecondaryButton = ({ onClick, buttonText }) => {
  return (
    <button className={classes["secondary-btn"]} onClick={onClick}>
      {buttonText}
    </button>
  );
};

export default SecondaryButton;
