import { Card } from "react-bootstrap";

import classes from "../css/exercise-card.module.css";

export default function ExerciseCard({ exercise, handleClick, isAdded }) {
  return (
    <>
      <Card style={{ width: "10rem", height: "8rem" }}>
        {/* <Card.Img
          variant="top"
          src="https://media.istockphoto.com/id/615883260/photo/difficult-doesnt-mean-impossible.jpg?s=612x612&w=is&k=20&c=3yo4Y73I7JRlHKF6ap9PFuvmuxNFI1tP4RmnNqUXk_E="
        /> */}
        <Card.Body className={classes["card-body"]}>
          <Card.Subtitle>{exercise.name}</Card.Subtitle>
          <div className={classes["exercise-card-footer"]}>
            {isAdded ? (
              <button
                id={exercise.id}
                className={classes["remove-exercise-button"]}
                onClick={handleClick}
              >
                x
              </button>
            ) : (
              <button
                id={exercise.id}
                className={classes["add-exercise-button"]}
                onClick={handleClick}
              >
                +
              </button>
            )}
            <Card.Text>
              {exercise.tags.map((tag) => (
                <span key={tag.id} className={classes["exercise-tag"]}>
                  {tag.name}
                </span>
              ))}
            </Card.Text>
          </div>
        </Card.Body>
      </Card>
    </>
  );
}
