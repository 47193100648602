import { memo, useState, useEffect, Fragment, forwardRef } from "react";
import { Stack } from "react-bootstrap";
import { Link } from "react-router-dom";

import classes from "../css/post.module.css";
import { useApi } from "../contexts/ApiProvider";
import { useFlash } from "../contexts/FlashProvider";
import { useUser } from "../contexts/UserProvider";
import TimeAgo from "./TimeAgo";
import DotButton from "./DotButton";
import DeleteButton from "../components/DeleteButton";
import Modal from "./Modal";

const Post = forwardRef(({ myaccount, post, resetPosts }, ref) => {
  const api = useApi();
  const flash = useFlash();
  // const user = useUser();
  const [user, setUser] = useState();
  const [showMore, setShowMore] = useState(false);
  const [showMenuOptions, setShowMenuOptions] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  useEffect(() => {
    (async () => {
      if (api.isAuthenticated()) {
        const response = await api.get("/me");
        setUser(response.ok ? response.body : null);
      } else {
        setUser(null);
      }
    })();
  }, [api]);

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  const handleMenuClick = () => {
    setShowMenuOptions(!showMenuOptions);
  };

  const closeDeleteModal = () => {
    setModalIsOpen(false);
    setShowMenuOptions(false);
  };

  const deletePost = async () => {
    setShowMenuOptions(false);
    setModalIsOpen(false);
    const response = await api.delete(`/posts/${post.id}`);
    if (response.ok) {
      flash("Post deleted", "success");
      resetPosts();
    } else {
      flash("Error deleting post", "danger");
    }
  };

  return (
    <Stack direction="vertical" className={classes["post-container"]} ref={ref}>
      <div className={classes["post-summary"]} key={post.id}>
        <div className={classes["post-header"]} key={post.id}>
          <div>
            <span className={classes["post-profile-picture"]}>
              {post.user.first_name[0].toUpperCase()}
            </span>
            <Link to={"/user/" + post.user.id + "/posts"}>
              {post.user.first_name} {post.user.last_name}
            </Link>
            &nbsp;&mdash;&nbsp; <TimeAgo isoDate={post.created_at} />
          </div>
          {user && user.id === post.user.id ? (
            <div className={classes["delete-post-container"]}>
              <DotButton onClick={handleMenuClick} />
              {showMenuOptions ? (
                <div>
                  <DeleteButton onClick={() => setModalIsOpen(true)} />
                  <Modal
                    open={modalIsOpen}
                    onClose={closeDeleteModal}
                    onConfirm={deletePost}
                    msg="Are you sure you want to delete this post?"
                  />
                </div>
              ) : null}
            </div>
          ) : null}
        </div>
        <span className={classes["post-title"]}>{post.title}</span>
        <span className={classes["post-description"]}>{post.description}</span>
      </div>
      <p>
        {showMore ? (
          <button className={classes["show-more-btn"]} onClick={toggleShowMore}>
            Hide Details
          </button>
        ) : (
          <button className={classes["show-more-btn"]} onClick={toggleShowMore}>
            Show Details
          </button>
        )}
      </p>

      {showMore ? (
        <table className={classes["plan-table"]}>
          <thead>
            <tr>
              <th>Exercise</th>
              <th>Reps</th>
              <th>Weight</th>
            </tr>
          </thead>
          <tbody>
            {post.completed_exercises.map((completedExercise, exercise_idx) =>
              completedExercise.completed_sets.map((completedSet, idx) => (
                <Fragment key={`${completedExercise.exercise_id}-${idx}`}>
                  <tr
                    key={`${completedExercise.exercise_id}-${idx}`}
                    className={
                      Number(exercise_idx) % 2 === 0
                        ? classes["shaded-row"]
                        : ""
                    }
                  >
                    {idx === 0 ? (
                      <td>{completedExercise.exercise_name}</td>
                    ) : (
                      <td></td>
                    )}
                    <td>{completedSet.reps}</td>
                    <td>{completedSet.weight}</td>
                  </tr>
                </Fragment>
              ))
            )}
          </tbody>
        </table>
      ) : null}
    </Stack>
  );
});

export default memo(Post);
