import { useState } from "react";
import { useNavigate } from "react-router-dom";

import classes from "../css/add-plan-page.module.css";
import { useApi } from "../contexts/ApiProvider";
import { useFlash } from "../contexts/FlashProvider";
import { useUser } from "../contexts/UserProvider";
import Body from "../components/Body";
import ExerciseGrid from "../components/ExerciseGrid";
import PlanDetails from "../components/PlanDetails";
import PrimaryButton from "../components/PrimaryButton";
import errorLogo from "../icons/error-sign.svg";

export default function AddPlanPage() {
  const api = useApi();
  const flash = useFlash();
  const { user } = useUser();
  const navigate = useNavigate();
  const [planTitle, setPlanTitle] = useState("");
  const [planTitleError, setPlanTitleError] = useState();
  const [exercisePlans, setExercisePlans] = useState([]);
  const [exerciseOptions, setExerciseOptions] = useState([]);
  const [submitIsValid, setSubmitIsValid] = useState();

  const saveWorkoutPlan = async () => {
    if (planTitle === "") {
      setPlanTitleError(true);
      flash("Enter a workout plan title and try again!", "danger");
      return;
    }
    const updatedExercisePlans = exercisePlans.map((exercisePlan) => {
      const { exercise_name, ...plan } = exercisePlan;
      return plan;
    });
    const response = await api.post("/workoutplan", {
      title: planTitle,
      exercise_plans: updatedExercisePlans,
    });
    if (response.ok) {
      flash("Workout plan was created!", "success");
      setTimeout(() => {
        navigate(`/user/${user.id}/plans`);
      }, 500);
    } else {
      flash("Error saving workout plan. Try again later!", "danger");
    }
  };

  const handleTitleChange = (e) => {
    setPlanTitle(e.target.value);
  };

  return (
    <Body>
      <div className={classes["new-plan-container"]}>
        <div className={classes["new-plan-header"]}>
          <h2>New Plan</h2>
        </div>
        <div className={classes["add-plan-container"]}>
          <div>
            <div className={classes["plan-header"]}>
              <div
                className={
                  `${classes["workout-plan-title"]}` +
                  " " +
                  (planTitleError ? `${classes["error"]}` : "")
                }
              >
                <input
                  type="text"
                  value={planTitle}
                  onChange={handleTitleChange}
                  placeholder="Plan Title"
                ></input>
                <div className={classes["error-container"]}>
                  <img
                    src={errorLogo}
                    className={
                      `${classes["error-logo"]}` + " " + `${classes["msg"]}`
                    }
                  />
                  <span className={classes["msg"]}>Plan title is required</span>
                </div>
              </div>
              <PrimaryButton onClick={saveWorkoutPlan} buttonText="Save" />
            </div>
            <PlanDetails
              exercisePlans={exercisePlans}
              setExercisePlans={setExercisePlans}
              setSubmitIsValid={setSubmitIsValid}
            />
          </div>
          <ExerciseGrid
            exerciseOptions={exerciseOptions}
            setExerciseOptions={setExerciseOptions}
            exercisePlans={exercisePlans}
            setExercisePlans={setExercisePlans}
          />
        </div>
      </div>
    </Body>
  );
}
