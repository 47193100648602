import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import classes from "../css/register.module.css";
import { useApi } from "../contexts/ApiProvider";
import Body from "../components/Body";
import PasswordRequirements from "../components/PasswordRequirements";

export default function RegisterPage() {
  const navigate = useNavigate();
  const api = useApi();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  // const [birthday, setBirthday] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [detailedPasswordError, setDetailedPasswordError] = useState("");
  const [formErrors, setFormErrors] = useState({});
  const [formSuccess, setFormSuccess] = useState(false);

  const today = new Date();
  const thirteenYearsAgo = new Date(today.setFullYear(today.getFullYear() - 13))
    .toISOString()
    .split("T")[0];

  const todayTwo = new Date();
  const hundredYearsAgo = new Date(
    todayTwo.setFullYear(todayTwo.getFullYear() - 100)
  )
    .toISOString()
    .split("T")[0];

  const registerUser = async (e) => {
    console.log("registering...");
    e.preventDefault();
    if (validateFormEntries()) {
      console.log("entries validated");
      const response = await api.post("/register", {
        first_name: firstName,
        last_name: lastName,
        email: email,
        // birthday: birthday,
        password: password,
      });
      if (response.ok) {
        setFormSuccess(true);
        setTimeout(() => {
          navigate("/feed");
        }, 500);
      } else {
        console.log("error registering user");
      }
    }
  };

  function validateFormEntries() {
    setFormErrors({});
    setDetailedPasswordError(false);
    const errors = {};

    let confirmPasswordRequiredError = true;
    let isValid = true;

    if (!firstName) {
      errors.first_name = "Required";
    } else if (!validateName(firstName)) {
      errors.first_name = "Invalid Name";
    }

    if (!lastName) {
      errors.last_name = "Required";
    } else if (!validateName(lastName)) {
      errors.last_name = "Invalid Name";
    }

    if (!email) {
      errors.email = "Required";
    } else if (!validateEmail(email)) {
      errors.email = "Invalid Email";
    }

    // if (!birthday) {
    //   errors.birthday = "Required";
    // } else if (!validateBirthday(birthday)) {
    //   errors.birthday = "Invalid Email";
    // }

    if (!password) {
      errors.password = "Required";
    } else if (!validatePassword(password)) {
      errors.password = "Invalid Password";
      setDetailedPasswordError(true);
    }

    if (!confirmPassword) {
      errors.confirm_password = "Required";
      confirmPasswordRequiredError = true;
    }

    if (
      !formErrors.password &&
      confirmPassword &&
      confirmPasswordRequiredError &&
      password !== confirmPassword
    ) {
      errors.confirm_password = "Password does not match";
    }

    setFormErrors(errors);
    if (Object.keys(errors).length !== 0) {
      isValid = false;
    }

    return isValid;
  }

  const validateName = (name) => {
    const namePattern = /^[a-z ,.'-]+$/i;
    return namePattern.test(name);
  };

  const validateEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  // const validateBirthday = (birthday) => {
  //   return true;
  // };

  const validatePassword = (password) => {
    const passwordPattern =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return passwordPattern.test(password);
  };

  return (
    <Body loggedOut={true}>
      <div className={classes["signup-container"]}>
        <h1 className={classes["signup-title"]}>Sign Up</h1>
        <form
          className={
            `${classes["form"]}` +
            " " +
            (formSuccess ? `${classes["success"]}` : "")
          }
          onSubmit={registerUser}
          noValidate
        >
          <div
            className={
              `${classes["input-group"]}` +
              " " +
              (formErrors.first_name ? `${classes["error"]}` : "")
            }
          >
            <label htmlFor="firstName">First Name</label>
            <input
              type="firstName"
              name="firstName"
              id="firstName"
              value={firstName}
              onChange={(e) => {
                setFirstName(e.target.value);
              }}
            />
            <span className={classes["msg"]}>{formErrors.first_name}</span>
          </div>
          <div
            className={
              `${classes["input-group"]}` +
              " " +
              (formErrors.last_name ? `${classes["error"]}` : "")
            }
          >
            <label htmlFor="lastName">Last Name</label>
            <input
              type="lastName"
              name="lastName"
              id="lastName"
              value={lastName}
              onChange={(e) => {
                setLastName(e.target.value);
              }}
            />
            <span className={classes["msg"]}>{formErrors.last_name}</span>
          </div>
          <div
            className={
              `${classes["input-group"]}` +
              " " +
              (formErrors.email ? `${classes["error"]}` : "")
            }
          >
            <label htmlFor="">Email</label>
            <input
              type="email"
              name="email"
              id="email"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
            <span className={classes["msg"]}>{formErrors.email}</span>
          </div>
          {/* <div
            className={
              `${classes["input-group"]}` +
              " " +
              (formErrors.birthday ? `${classes["error"]}` : "")
            }
          >
            <label htmlFor="">Birthday</label>
            <input
              className={classes["input-birthday"]}
              type="date"
              name="birthday"
              id="birthday"
              value={birthday}
              onChange={(e) => {
                setBirthday(e.target.value);
              }}
              max={thirteenYearsAgo}
              min={hundredYearsAgo}
              placeholder=""
            />
            <span className={classes["msg"]}>{formErrors.birthday}</span>
          </div> */}
          <div
            className={
              `${classes["input-group"]}` +
              " " +
              (formErrors.password ? `${classes["error"]}` : "")
            }
          >
            <label htmlFor="password">Password</label>
            <input
              type="password"
              name="password"
              id="password"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
            {detailedPasswordError ? (
              <PasswordRequirements />
            ) : (
              <span className={classes["msg"]}>{formErrors.password}</span>
            )}
          </div>
          {/* {detailedPasswordError ? (
            <div
              className={
                `${classes["input-group"]}` + " " + `${classes["error"]}`
              }
            >
              <span className={classes["msg"]}>{detailedPasswordError}</span>
            </div>
          ) : null} */}
          <div
            className={
              `${classes["input-group"]}` +
              " " +
              (formErrors.confirm_password ? `${classes["error"]}` : "")
            }
          >
            <label htmlFor="confirmPassword">Confirm Password</label>
            <input
              type="password"
              name="confirmPassword"
              id="confirmPassword"
              value={confirmPassword}
              onChange={(e) => {
                setConfirmPassword(e.target.value);
              }}
            />
            <span className={classes["msg"]}>
              {formErrors.confirm_password}
            </span>
          </div>
          <button className={classes["signup-button"]}>Submit</button>
        </form>
        <div className={classes["login-container"]}>
          <span>Already have an account?</span>
          <Link to={"/login"}>Login</Link>
        </div>
      </div>
    </Body>
  );
}
