import { useState, useEffect } from "react";
import classes from "../css/time-ago.module.css";

const secondsTable = [
  ["year", 60 * 60 * 24 * 365],
  ["month", 60 * 60 * 24 * 30],
  ["week", 60 * 60 * 24 * 7],
  ["day", 60 * 60 * 24],
  ["hour", 60 * 60],
  ["minute", 60],
];

const rtf = new Intl.RelativeTimeFormat(undefined, { numeric: "auto" });

function getTimeAgo(date) {
  // Calculate the difference between the current time and the given date
  const now = new Date();
  const seconds = Math.round((date.getTime() - now.getTime()) / 1000);
  const absSeconds = Math.abs(seconds);
  let bestUnit, bestTime, bestInterval;
  console.log(`Current time: ${now}`);
  console.log(`Seconds difference: ${seconds}`);

  for (let [unit, unitSeconds] of secondsTable) {
    if (absSeconds >= unitSeconds) {
      bestUnit = unit;
      bestTime = Math.round(seconds / unitSeconds);
      bestInterval = unitSeconds / 2;
      break;
    }
  }

  // Fallback to seconds if no unit matched
  if (!bestUnit) {
    bestUnit = "second";
    bestTime = Math.round(seconds);
    bestInterval = 10;
  }

  return [bestTime, bestUnit, bestInterval];
}

export default function TimeAgo({ isoDate }) {
  const date = new Date(Date.parse(isoDate + "Z"));
  console.log(isoDate);
  console.log(`Parsed date: ${date}`);

  // Get the relative time components
  const [time, unit, interval] = getTimeAgo(date);

  // Set up state and re-render at appropriate intervals
  const [, setUpdate] = useState(0);

  useEffect(() => {
    const timerId = setInterval(
      () => setUpdate((update) => update + 1),
      interval * 1000
    );
    return () => clearInterval(timerId);
  }, [interval]);

  console.log(time);
  console.log(unit);

  return <span className={classes["post-date"]}>{rtf.format(time, unit)}</span>;
}
