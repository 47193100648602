import React from "react";
import classes from "../css/password-requirements.module.css";

const PasswordRequirements = () => (
  <div className={classes["password-requirements"]}>
    <p>Your password must meet the following requirements:</p>
    <ul>
      <li>At least 8 characters long</li>
      <li>Contain both uppercase and lowercase letters</li>
      <li>Include at least 1 number</li>
      <li>Include at least 1 special character</li>
    </ul>
  </div>
);

export default PasswordRequirements;
