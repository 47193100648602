import React from "react";

import classes from "../css/buttons.module.css";

const DeleteButton = ({ onClick }) => {
  return (
    <button className={classes["delete-btn"]} onClick={onClick}>
      Delete
    </button>
  );
};

export default DeleteButton;
