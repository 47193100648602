import { useState, useEffect } from "react";
import { Pagination, Spinner } from "react-bootstrap";

import classes from "../css/exercise-grid.module.css";
import { useApi } from "../contexts/ApiProvider";
import ExerciseCard from "../components/ExerciseCard";
import Dropdown from "../components/Dropdown";

export default function ExerciseGrid({
  exercisePlans,
  setExercisePlans,
  exerciseOptions,
  setExerciseOptions,
}) {
  const api = useApi();
  const [exerciseTags, setExerciseTags] = useState([]);
  const [activeTag, setActiveTag] = useState("All");
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    (async () => {
      getExerciseOptions("1", activeTag);
      getExerciseTags();
    })();
  }, [api, activeTag]);

  const getExerciseOptions = async (pageNumber, tag) => {
    let response;
    if (tag !== "All") {
      response = await api.get(`/exercise?page=${pageNumber}&tag=${tag}`);
    } else {
      response = await api.get(`/exercise?page=${pageNumber}`);
    }
    if (response.ok) {
      setExerciseOptions(response.body.exercises);
      setTotalPages(response.body.total_pages);
    } else {
      setExerciseOptions(null);
    }
  };

  const getExerciseTags = async () => {
    const response = await api.get("/tag");
    if (response.ok) {
      const tags = response.body;
      tags.unshift({ name: "All", id: 0 });
      setExerciseTags(tags);
    } else {
      setExerciseTags(null);
    }
  };

  const paginationItems = [];
  for (let i = 1; i <= totalPages; i++) {
    paginationItems.push(
      <Pagination.Item
        key={i}
        id={i}
        onClick={() => getExerciseOptions(i, activeTag)}
      >
        {i}
      </Pagination.Item>
    );
  }

  const handleTagFilterChange = (e) => {
    setActiveTag(e.target.getAttribute("data-name"));
    getExerciseOptions("1", e.target.getAttribute("data-name"));
  };

  const removeExercise = (e) => {
    const exerciseId = Number(e.target.id);
    const updatedExercisePlans = exercisePlans.filter(
      (exercisePlan) => exercisePlan.exercise_id !== exerciseId
    );

    setExercisePlans(updatedExercisePlans);
  };

  const addExercise = (e) => {
    const exerciseId = e.target.id;
    const exercise = exerciseOptions.find(
      (exerciseOption) => exerciseOption.id === Number(exerciseId)
    );

    let defaultSetPlan = {
      reps: 6,
      weight: exercise.default_weight,
    };

    let setPlans = [];

    for (let i = 1; i <= 3; i++) {
      setPlans.push(defaultSetPlan);
    }

    let newExercisePlan = {
      exercise_id: exercise.id,
      exercise_name: exercise.name,
      set_plans: setPlans,
    };

    setExercisePlans([...exercisePlans, newExercisePlan]);
  };

  return (
    <div>
      {exerciseOptions === undefined || exercisePlans === undefined ? (
        <Spinner animation="border" />
      ) : (
        <>
          {exerciseOptions === null ? (
            <p>Could not retrieve exercises</p>
          ) : (
            <>
              {exerciseOptions.length === 0 ? (
                <p>There are no exercises</p>
              ) : (
                <div className={classes["exercise-options-container"]}>
                  <Dropdown
                    dropdownOptions={exerciseTags}
                    activeDropdownOption={activeTag}
                    handleDropdownItemSelection={handleTagFilterChange}
                  />
                  <div className={classes["exercise-cards-grid"]}>
                    {exerciseOptions.map((exerciseOption) =>
                      exercisePlans.some(
                        (exercisePlan) =>
                          exercisePlan.exercise_id === exerciseOption.id
                      ) ? (
                        <ExerciseCard
                          key={exerciseOption.id}
                          exercise={exerciseOption}
                          handleClick={removeExercise}
                          isAdded={true}
                        />
                      ) : (
                        <ExerciseCard
                          key={exerciseOption.id}
                          exercise={exerciseOption}
                          handleClick={addExercise}
                          isAdded={false}
                        />
                      )
                    )}
                  </div>
                  <Pagination>
                    <Pagination.Prev />
                    {paginationItems}
                    <Pagination.Next />
                  </Pagination>
                </div>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
}
