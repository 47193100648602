import React from "react";

import classes from "../css/error.module.css";

const Error = () => {
  return (
    <div className={classes["full-screen-container"]}>
      <div className={classes["error-container"]}>
        <h3>Server Error</h3>
      </div>
    </div>
  );
};

export default Error;
