import { useState, useEffect } from "react";
import React, { Fragment } from "react";
import { Spinner, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

// import classes from "../css/workout-plans.module.css";
import classes from "../css/add-post-page.module.css";
import { useApi } from "../contexts/ApiProvider";
import { useFlash } from "../contexts/FlashProvider";
import { useUser } from "../contexts/UserProvider";
import Body from "../components/Body";
import Dropdown from "../components/Dropdown";
import PrimaryButton from "../components/PrimaryButton";
import SecondaryButton from "../components/SecondaryButton";
import LoadingIcon from "../components/LoadingIcon";

export default function AddPostPage() {
  const api = useApi();
  const flash = useFlash();
  const navigate = useNavigate();
  const { user } = useUser();
  const [plans, setPlans] = useState();
  const [activePlan, setActivePlan] = useState({ name: "Choose Workout" });
  const [workoutTitle, setWorkoutTitle] = useState("");
  const [description, setDescription] = useState("");
  const [workoutTitleError, setWorkoutTitleError] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [placeholderTitle, setPlaceholderTitle] = useState("");

  // table states
  const [readOnlyMode, setReadOnlyMode] = useState(false);
  const [submitIsValid, setSubmitIsValid] = useState();
  const [exercisePlans, setExercisePlans] = useState();
  const [enablePublish, setEnablePublish] = useState(false);
  const [exerciseList, setExerciseList] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    const hour = new Date().getHours();
    if (hour < 12) {
      setPlaceholderTitle("Morning Lift");
    } else if (hour < 18) {
      setPlaceholderTitle("Afternoon Lift");
    } else {
      setPlaceholderTitle("Evening Lift");
    }
  }, []);

  useEffect(() => {
    (async () => {
      const response = await api.get("/workoutplan");
      if (response.ok) {
        setPlans(response.body);
      } else {
        setPlans(null);
      }
    })();
  }, [api]);

  useEffect(() => {
    (async () => {
      const response = await api.get("/exercise");
      if (response.ok) {
        setExerciseList(response.body.exercises);
      } else {
        setExerciseList(null);
      }
    })();
  }, [api]);

  const updateExercisePlanParams = (plans) => {
    const updatedExercisePlans = plans.map((exercisePlan) => {
      const { workout_plan_id, exercise, ...plan } = exercisePlan;
      plan.exercise_id = exercise.id;
      plan.exercise_name = exercise.name;
      return plan;
    });
    setExercisePlans(updatedExercisePlans);
  };

  const handleDropdownSelection = (e) => {
    const selectedPlan = plans.find(
      (plan) => plan.title === e.target.getAttribute("data-name")
    );
    setActivePlan({
      id: selectedPlan.id,
      name: e.target.getAttribute("data-name"),
    });
    setEnablePublish(true);
    setExercisePlans(selectedPlan.exercise_plans);
    updateExercisePlanParams(selectedPlan.exercise_plans);
  };

  const handleRepChange = (e) => {
    const id = e.target.id;
    const [exerciseId, setIndex] = id.split("-").map(Number);

    let value = e.target.value;
    if (value === "") {
      setSubmitIsValid(false);
    } else {
      setSubmitIsValid(true);
    }

    if (value) {
      value = Number(value);
    }

    setExercisePlans((exercisePlans) =>
      exercisePlans.map((exercisePlan) => {
        if (exercisePlan.exercise_id === exerciseId) {
          return {
            ...exercisePlan,
            set_plans: exercisePlan.set_plans.map((set_plan, idx) =>
              idx === setIndex ? { ...set_plan, reps: value } : set_plan
            ),
          };
        }
        return exercisePlan;
      })
    );
  };

  const handleWeightChange = (e) => {
    const id = e.target.id;
    const [exerciseId, setIndex] = id.split("-").map(Number);

    let value = e.target.value;
    if (value === "") {
      setSubmitIsValid(false);
    } else {
      setSubmitIsValid(true);
    }

    if (value) {
      value = Number(value);
    }

    setExercisePlans((exercisePlans) =>
      exercisePlans.map((exercisePlan) => {
        if (exercisePlan.exercise_id === exerciseId) {
          return {
            ...exercisePlan,
            set_plans: exercisePlan.set_plans.map((set_plan, idx) =>
              idx === setIndex ? { ...set_plan, weight: value } : set_plan
            ),
          };
        }
        return exercisePlan;
      })
    );
  };

  const addSet = (e) => {
    const id = e.target.id;
    const [exercise_id, idx] = id.split("-");
    setExercisePlans((exercisePlans) =>
      exercisePlans.map((exercisePlan) => {
        if (exercisePlan.exercise_id === Number(exercise_id)) {
          if (exercisePlan.set_plans.length < 5) {
            const newSet = exercisePlan.set_plans[idx];
            exercisePlan.set_plans.splice(Number(idx) + 1, 0, newSet);
          }
          return exercisePlan;
        }
        return exercisePlan;
      })
    );
  };

  const removeSet = (e) => {
    const id = e.target.id;
    const [exerciseId, setIndex] = id.split("-").map(Number);

    setExercisePlans(
      (exercisePlans) =>
        exercisePlans
          .map((exercisePlan) => {
            if (exercisePlan.exercise_id === exerciseId) {
              if (exercisePlan.set_plans.length > 1) {
                // Remove the specific set from set_plans
                const updatedSetPlans = [...exercisePlan.set_plans];
                updatedSetPlans.splice(setIndex, 1);
                return {
                  ...exercisePlan,
                  set_plans: updatedSetPlans,
                };
              }
              // If there's only one set, return null to indicate it should be removed
              return null;
            }
            return exercisePlan;
          })
          .filter((exercisePlan) => exercisePlan !== null) // Remove any null values from the array
    );
  };

  const savePost = async () => {
    const updatedExercisePlans = exercisePlans.map((exercisePlan) => {
      const { exercise_name, ...plan } = exercisePlan;
      plan.completed_sets = plan.set_plans;
      delete plan.set_plans;
      delete plan.id;
      return plan;
    });
    console.info(updatedExercisePlans);
    const response = await api.post(`/posts`, {
      title: workoutTitle ? workoutTitle : placeholderTitle,
      description: description,
      completed_exercises: updatedExercisePlans,
    });
    if (response.ok) {
      flash("Workout recorded successfully", "success");
      if (isChecked) {
        updatePlan();
      }
      setTimeout(() => {
        navigate(`/feed`);
      }, 200);
    } else {
      flash("Error recording workout", "danger");
    }
  };

  const updatePlan = async () => {
    const updatedExercisePlans = exercisePlans.map((exercisePlan) => {
      const { exercise_name, ...plan } = exercisePlan;
      delete plan.id;
      return plan;
    });

    const response = await api.put(`/workoutplan/${activePlan.id}`, {
      title: activePlan.name,
      exercise_plans: updatedExercisePlans,
    });
    if (response.ok) {
      flash("Workout plan successfully updated", "success");
    } else {
      flash("Error updating workout plan", "danger");
    }
  };

  const discardPost = () => {
    navigate("/feed");
  };

  const handleTitleChange = (e) => {
    setWorkoutTitle(e.target.value);
  };

  const handleDescriptionChange = (e) => {
    if (e.target.value.length > 280) {
      return;
    }
    setDescription(e.target.value);
  };

  const handleAddPlanClick = () => {
    navigate("/plans/new");
  };

  const renderTooltip = (msg) => <Tooltip id="button-tooltip">{msg}</Tooltip>;

  const handleSearch = (value) => {
    setSearchValue(value);
    const searchTerm = value.toLowerCase();
    if (searchTerm) {
      const res = exerciseList.filter((exercise) => {
        const isInPlans = exercisePlans.some(
          (plan) => plan.exercise_id === exercise.id
        );

        return !isInPlans && exercise.name.toLowerCase().includes(searchTerm);
      });
      setSearchResults(res);
    } else {
      setSearchResults([]);
    }
  };

  const handleSearchSelection = (e) => {
    addExercise(e);
    handleSearch("");
  };

  const addExercise = (e) => {
    const exerciseId = e.target.id;
    const exercise = exerciseList.find(
      (exerciseOption) => exerciseOption.id === Number(exerciseId)
    );

    let defaultSetPlan = {
      reps: 6,
      weight: exercise.default_weight,
    };

    let setPlans = [];

    for (let i = 1; i <= 3; i++) {
      setPlans.push(defaultSetPlan);
    }

    let newExercisePlan = {
      exercise_id: exercise.id,
      exercise_name: exercise.name,
      set_plans: setPlans,
    };

    setExercisePlans([...exercisePlans, newExercisePlan]);
  };

  return (
    <Body>
      <div className={classes["post-new-container"]}>
        <div className={classes["post-new-header"]}>
          <h2>New Post</h2>
        </div>
        {plans === undefined ? (
          <LoadingIcon />
        ) : (
          <>
            {plans === null ? (
              <p>Could not retrieve workout plans</p>
            ) : (
              <div className={classes["plan-container"]}>
                {plans.length === 0 ? (
                  <div className={classes["add-post-footer"]}>
                    <span>Add a workout plan to get started</span>
                    <button
                      className={classes["add-plan-button"]}
                      onClick={handleAddPlanClick}
                    >
                      + Add
                    </button>
                  </div>
                ) : (
                  <div className={classes["post-table-container"]}>
                    <Dropdown
                      dropdownOptions={plans}
                      activeDropdownOption={activePlan.name}
                      handleDropdownItemSelection={handleDropdownSelection}
                    />
                    {activePlan.name !== "Choose Workout" ? (
                      <div className={classes["post-table-container"]}>
                        <table className={classes["plan-table"]}>
                          <thead>
                            <tr>
                              <th>Exercise</th>
                              <th>Reps</th>
                              <th>Weight</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            {exercisePlans.map((exercisePlan, exercise_idx) =>
                              exercisePlan.set_plans.map((setPlan, idx) => (
                                <Fragment
                                  key={`${exercisePlan.exercise_id}-${idx}`}
                                >
                                  <tr
                                    key={`${exercisePlan.exercise_id}-${idx}`}
                                    className={
                                      Number(exercise_idx) % 2 === 0
                                        ? classes["shaded-row"]
                                        : ""
                                    }
                                  >
                                    {idx === 0 ? (
                                      <td>{exercisePlan.exercise_name}</td>
                                    ) : (
                                      <td></td>
                                    )}
                                    <td>
                                      {!readOnlyMode ? (
                                        <input
                                          id={`${exercisePlan.exercise_id}-${idx}`}
                                          type="number"
                                          value={setPlan.reps}
                                          onChange={handleRepChange}
                                          className={
                                            setPlan.reps === ""
                                              ? `${classes["error"]}`
                                              : ""
                                          }
                                        />
                                      ) : (
                                        <input
                                          id={`${exercisePlan.exercise_id}-${idx}`}
                                          type="number"
                                          value={setPlan.reps}
                                          disabled
                                          className={
                                            readOnlyMode
                                              ? `${classes["read-only"]}`
                                              : ""
                                          }
                                        />
                                      )}
                                    </td>
                                    <td>
                                      {!readOnlyMode ? (
                                        <input
                                          id={`${exercisePlan.exercise_id}-${idx}`}
                                          type="number"
                                          value={setPlan.weight}
                                          onChange={handleWeightChange}
                                          className={
                                            setPlan.weight === ""
                                              ? `${classes["error"]}`
                                              : ""
                                          }
                                        />
                                      ) : (
                                        <input
                                          id={`${exercisePlan.exercise_id}-${idx}`}
                                          type="number"
                                          value={setPlan.weight}
                                          disabled
                                          className={
                                            readOnlyMode
                                              ? `${classes["read-only"]}`
                                              : ""
                                          }
                                        />
                                      )}
                                    </td>
                                    <td>
                                      {!readOnlyMode ? (
                                        <div
                                          className={
                                            classes["set-button-container"]
                                          }
                                        >
                                          <div
                                            className={
                                              classes["button-with-tooltip"]
                                            }
                                          >
                                            <OverlayTrigger
                                              placement="bottom"
                                              delay={{ show: 100, hide: 100 }}
                                              overlay={renderTooltip(
                                                "remove set"
                                              )}
                                            >
                                              <button
                                                id={`${exercisePlan.exercise_id}-${idx}`}
                                                className={
                                                  classes["remove-set-button"]
                                                }
                                                onClick={removeSet}
                                              >
                                                -
                                              </button>
                                            </OverlayTrigger>
                                            <span
                                              className={classes["tooltip"]}
                                            >
                                              Delete Set
                                            </span>
                                          </div>
                                          <div
                                            className={
                                              classes["button-with-tooltip"]
                                            }
                                          >
                                            <OverlayTrigger
                                              placement="bottom"
                                              delay={{ show: 100, hide: 100 }}
                                              overlay={renderTooltip(
                                                "duplicate set"
                                              )}
                                            >
                                              <button
                                                id={`${exercisePlan.exercise_id}-${idx}`}
                                                className={
                                                  classes["add-set-button"]
                                                }
                                                onClick={addSet}
                                              >
                                                +
                                              </button>
                                            </OverlayTrigger>
                                            <span
                                              className={classes["tooltip"]}
                                            >
                                              Add Set
                                            </span>
                                          </div>
                                        </div>
                                      ) : null}
                                    </td>
                                  </tr>
                                </Fragment>
                              ))
                            )}
                          </tbody>
                        </table>
                        <div className={classes["search-container"]}>
                          <input
                            type="text"
                            placeholder="quick add"
                            onChange={(e) => {
                              handleSearch(e.target.value);
                            }}
                            value={searchValue}
                            className={classes["search-bar"]}
                          />
                          <ul
                            className={
                              `${classes["menu"]}` +
                              " " +
                              (searchResults.length > 0
                                ? `${classes["menu-open"]}`
                                : "")
                            }
                          >
                            {searchResults.map((result) => (
                              <li
                                key={result.id}
                                id={result.id}
                                onClick={handleSearchSelection}
                              >
                                {result.name}
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    ) : null}
                  </div>
                )}
              </div>
            )}
            <div
              className={
                `${classes["post-title"]}` +
                (workoutTitleError ? `${classes["error"]}` : "")
              }
            >
              <label>Title</label>
              <input
                type="text"
                value={workoutTitle}
                onChange={handleTitleChange}
                placeholder={placeholderTitle}
              ></input>
            </div>
            <div>
              <label>Description</label>
              <textarea
                className={classes["post-description"]}
                value={description}
                onChange={handleDescriptionChange}
                placeholder="How did the workout go?"
              >
                <p>{description}</p>
              </textarea>
              <div className={classes["character-limit"]}>
                {description.length}/280
              </div>
            </div>
          </>
        )}
        <div className={classes["post-header"]}>
          <div className={classes["post-checkbox-update"]}>
            <label htmlFor="update-checkbox">Update Workout Plan?</label>
            <input
              type="checkbox"
              id="update-checkbox"
              checked={isChecked}
              onChange={() => {
                setIsChecked(!isChecked);
              }}
            />
          </div>
          <div className={classes["post-btn-container"]}>
            <SecondaryButton onClick={discardPost} buttonText="Discard" />
            <PrimaryButton
              disabled={!enablePublish}
              onClick={savePost}
              buttonText="Publish"
            />
          </div>
        </div>
      </div>
    </Body>
  );
}
