import {
  useContext,
  createContext,
  useState,
  useEffect,
  useCallback,
} from "react";
import { useApi } from "./ApiProvider";

const UserContext = createContext();

export default function UserProvider({ children }) {
  const [user, setUser] = useState(undefined);
  const [theme, setTheme] = useState(() => {
    return localStorage.getItem("theme") || "light";
  });
  const api = useApi();

  useEffect(() => {
    (async () => {
      if (api.isAuthenticated()) {
        const response = await api.get("/me");
        setUser(response.ok ? response.body : null);
      } else {
        setUser(false);
      }
    })();
  }, [api]);

  const login = useCallback(
    async (username, password) => {
      const result = await api.login(username, password);
      console.log("api login result: " + result);
      if (result === "ok") {
        const response = await api.get("/me");
        setUser(response.ok ? response.body : null);
        // setUser(true);
      }
      return result;
    },
    [api]
  );

  const logout = useCallback(async () => {
    await api.logout();
    setUser(null);
  }, [api]);

  const toggleTheme = async () => {
    const newTheme = theme === "light" ? "dark" : "light";
    setTheme(newTheme);
    localStorage.setItem("theme", newTheme);
  };

  return (
    <UserContext.Provider
      value={{ user, setUser, login, logout, theme, toggleTheme }}
    >
      {children}
    </UserContext.Provider>
  );
}

export function useUser() {
  return useContext(UserContext);
}
