import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import classes from "../css/user-page.module.css";
import { useApi } from "../contexts/ApiProvider";
import { useFlash } from "../contexts/FlashProvider";
import { useUser } from "../contexts/UserProvider";
import Body from "../components/Body";
import Posts from "../components/Posts";
import Plans from "../components/Plans";
import PrimaryButton from "../components/PrimaryButton";
import formattedDate from "../utils/date_utils";
import Sidebar from "../components/Sidebar";
import LoadingIcon from "../components/LoadingIcon";

export default function UserPage({ type }) {
  const api = useApi();
  const flash = useFlash();
  const { userid } = useParams();
  const [user, setUser] = useState();
  const { user: loggedInUser } = useUser();
  const [isFollower, setIsFollower] = useState();
  const [isMyAccount, setIsMyAccount] = useState(false);
  const [userStats, setUserStats] = useState();

  useEffect(() => {
    (async () => {
      const response = await api.get("/user/" + userid + "/stats");
      if (response.ok) {
        setUserStats(response.body);
      } else {
        setUserStats(null);
      }
    })();
  }, [api]);

  useEffect(() => {
    (async () => {
      const response = await api.get("/user/" + userid);
      if (response.ok) {
        setUser(response.body);

        if (Number(userid) !== loggedInUser.id) {
          const follower = await api.get("/me/following/" + userid);
          if (follower.status === 204) {
            setIsFollower(true);
          } else if (follower.status === 404) {
            setIsFollower(false);
          }
        } else {
          // setIsFollower(null);
          if (!isMyAccount) {
            setIsMyAccount(true);
          }
        }
      } else {
        setUser(null);
      }
    })();
  }, [api, userid]);

  const follow = async () => {
    const response = await api.post("/me/following/" + userid);
    if (response.ok) {
      flash(
        <>
          You are now following{" "}
          <b>
            {user.first_name} {user.last_name}
          </b>
        </>,
        "success"
      );
      setIsFollower(true);
    }
  };

  const unfollow = async () => {
    const response = await api.delete("/me/following/" + userid);
    if (response.ok) {
      flash(
        <>
          You have unfollowed{" "}
          <b>
            {user.first_name} {user.last_name}
          </b>
        </>,
        "success"
      );
      setIsFollower(false);
    }
  };

  const [content, setContent] = useState({ id: user?.id, myAccount: false });

  useEffect(() => {
    if (user && loggedInUser) {
      const isMyAccount = Number(userid) === loggedInUser.id;
      setContent({ id: user.id, myAccount: isMyAccount });
    }
  }, [user, loggedInUser]);

  return (
    <Body>
      {user === undefined ? (
        <LoadingIcon />
      ) : (
        <>
          {user === null ? (
            <p>User not found</p>
          ) : (
            <div className={classes["user-page-container"]}>
              <Sidebar userid={user.id} type={type} />
              <div className={classes["user-page-content"]}>
                <div className={classes["user-page-header"]}>
                  <div>
                    <h3>
                      {user.first_name} {user.last_name}
                    </h3>
                    <p className={classes["joined-date"]}>
                      Joined {formattedDate(user.created_at)}
                    </p>
                    <div className={classes["follow-container"]}>
                      <ul>
                        <li>
                          <span>Following</span>
                          {userStats === undefined ? (
                            <LoadingIcon />
                          ) : (
                            <div>{userStats.following_count}</div>
                          )}
                        </li>
                        <li>
                          <span>Followers</span>
                          {userStats === undefined ? (
                            <LoadingIcon />
                          ) : (
                            <div>{userStats.followers_count}</div>
                          )}
                        </li>
                        <li>
                          <span>Posts</span>
                          {userStats === undefined ? (
                            <LoadingIcon />
                          ) : (
                            <div>{userStats.posts_count}</div>
                          )}
                        </li>
                      </ul>
                    </div>
                  </div>

                  {isFollower === false && (
                    <PrimaryButton onClick={follow} buttonText="Follow" />
                  )}

                  {isFollower === true && (
                    <PrimaryButton onClick={unfollow} buttonText="Unfollow" />
                  )}
                </div>
                <div>
                  {content.id !== undefined ? (
                    <>
                      {type === "posts" ? (
                        <Posts
                          // content={{
                          //   id: user.id,
                          //   myAccount: isMyAccount,
                          // }}
                          content={content}
                        />
                      ) : (
                        <Plans />
                      )}{" "}
                    </>
                  ) : null}
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </Body>
  );
}
